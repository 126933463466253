import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import person from "../images/svg/person-chat.svg"

const ContactPage = ({ location }) => {


  return (
    <Layout>
      <Seo title="Contact" />
      <div class="page-header">
          <div class="header-container">


            <div class="header-copy">
              <h1>Contact</h1>
              <p>Want to chat? - Coming soon...</p>
            </div>
            <div class="header-image">
              <img src={person} alt="Person worried" style={{ width: "180px", margin: "0 auto" }} />
            </div>
          </div>
        </div>

    
      <div className="page-body">

        

        <p></p>
        
      </div>
    </Layout>
  );

};



export default ContactPage;
